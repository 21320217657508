<template>
  <b-container class="quickScore" :class="{'adt' : develop == 'adt'}">
    <div>
      <ReportTable
        :id="id"
        :data="data"
        :title="title"
        :home="home"
        :updown="updown"
        :config="config"
        :team="teamEvent"
        :match="match"
        :tmparams="tmparams"
        :showmore="showmore"
        :Year="Year"
        :course_code="course_code"
        :Stableford="Stableford"
        :season="season"
        :code="code"
        :develop="develop"
      />
    </div>
  </b-container>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";
import axios from 'axios';
export default {
  name: "Tournament Reports",
  components: {
    ReportTable,
  },
  props: [
    "develop",
    "id",
    "showmore",
    "Year",
    "courseCode",
    "currentReport", 
    "reportURL",
    "config",
    "multi_tourns",
    "pastT",
    "head",
    "tm",
    "past_tourns",
    "tm_params",
    "course",
    "course_code",
    "tmparams",
    "Stableford",
    "season",
    "code",
    "match"
  ],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      data: [],
      tab: "tab",
      cell: "cell",
      home: false,
      second_tourn_tixc: {},
      winnerType: {},
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.data.live_scoring === "Y") message = " - Livescoring is on";
      else message = " - " + this.data.round_head;
      return message;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    title: function(title) {
      if (this.currentReport.includes("entry")) title = "Tournament Entries";
      else if (this.currentReport.includes("draw")) title = "Draw";
      else if (this.currentReport.includes("latest")) title = 'Round ' + this.data.pft_round + ' Scores Only';
      else if (this.currentReport.includes("score") && this.data.pft_round != '1') title = 'Round ' + this.data.pft_round + ' Scoreboard';
      else if (this.currentReport.includes("score") && this.data.pft_round == '1') title = 'Round ' + this.data.pft_round + ' Scores Only';
      else if (this.currentReport.includes("result")) title = "Final Result";

      return title;
    },
  },
  methods: {
    updateStuff: function() {
      apiCall
        .report(this.currentReport)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    swapTour(tournCode) {
      this.course = tournCode;
      this.updateStuff();
      this.getSecondTournTicx(tournCode);
    },
    getSecondTournTicx(code) {
      console.log("IN")
      axios
        .get(this.currentReport)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_APIFULL_URL +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.second_tourn_tixc = response.data;
          console.log("this.second_tourn_tixc")
          console.log(this.second_tourn_tixc)
          this.winnerType = response.data.winner_result;
          if (typeof this.second_tourn_tixc.reports != "undefined") {
            this.second_tourn_tixc.reports.reports_entry.forEach(element => {
              if (element.report_url.indexOf("tmscore") !== -1) {
                this.report = element.report_url;
              }
              if (element.report_url.indexOf("tmresult") !== -1) {
                this.report = element.report_url;
                this.updateStuff();
              }
            });
          }
        });
    },
    upDown() {
      this.updown = !this.updown;
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    apiCall
      .report(this.currentReport)
      .then(({ data }) => {
        this.data = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.quickScore.container.adt > div  > div > table > tbody > div#Results > div.title {
  background-color: #76c3bd!important;
}
.FullListingBtn > a.btn {
  background-color: #042F62;
  border-radius: 0;
  color: #fff!important;
  width: 100%;
  height: 59px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 3.1;
  border-color: transparent;
}
.FullListingBtn > a.btn:hover {
  background-color: #5897E6;
  border-radius: 0;
  color: #fff!important;
  width: 100%;
  height: 59px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 3.1;
  border-color: transparent;
}
::v-deep li.nav-item.Flisting-btn.right {
  display: block;
  padding: 0;
}
.quickScore {
  padding: 0 0 50px 0;
}
li {
  list-style: none;
}
.links {
  text-align: center;
  padding: 10px;
}
.FullList {
  background-color: #0A3F7F;
}
.blue {
  background-color: #1a2337;
}
.links > li > a {
  color: #fff !important;
}
::v-deep .nav-item {
  font-size: 14px;
  font-weight: 500;
}
::v-deep #reportTable {
  padding-bottom: 0!important;
  margin-bottom: 0px;
  overflow: hidden!important;
}
::v-deep .row.pastbtnRow {
  /* top: 1px;
  right: 21.8%;
  position: absolute;
  width: 300px; */
  /* margin-left: 0px !important;
  margin-right: 0px !important; */
  margin-top: 5px !important;
}
::v-deep button.pastbtn {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #5897E6;
  color: #fff;
  border-radius: 0;
  border: 0px solid transparent;
  font-size: 11px;
  height: 45px;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn {
  /* background: #005699; */
  background: #042F62;
  color: #fff;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn:hover {
  background: #042F62;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep .padRight {
  padding-right: 0;
}
::v-deep .padLeft {
  padding-left: 0;
}
</style>
<style src="@/css/ADThbh.css"></style>
<style src="@/css/orginalReportCard.css"></style>
<style src="@/css/ADTorginalReportCard.css"></style>
<style src="@/css/playerReportCard.css"></style>
<style src="@/css/ADTplayerReportCard.css"></style>
<style src="@/css/ADTresults.css"></style>